var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-0 md:ml-6",staticStyle:{"margin-top":"25px","height":"122px"}},[_c('div',{staticStyle:{"padding-bottom":"0px","height":"34px","width":"calc(100% - 40px)","margin-top":"0px","margin-left":"0px","border-bottom":"1px solid rgba(255,255,255,0.2)","white-space":"nowrap"}},[_c('div',{staticStyle:{"border-right":"1px solid rgba(255,255,255,0.2)","height":"34px","padding-bottom":"30px","position":"relative","display":"inline-block","cursor":"pointer","opacity":"1","float":"left","text-align":"center","padding-left":"28px","padding-right":"28px"},on:{"click":function($event){return _vm.addNewSchedule()}}},[_c('span',{staticClass:"material-icons",staticStyle:{"float":"left","color":"rgba(255,255,255,0.9)","font-size":"16px","font-weight":"400","margin-top":"2px"}},[_vm._v(" add ")]),_c('p',{staticStyle:{"float":"left","margin-left":"8px","color":"rgba(255,255,255,0.9)","font-size":"14px"}},[_vm._v(" Add Schedule ")])]),_vm._l((_vm.schedules),function(schedule,index){return _c('div',{key:schedule.ID,class:{
				'nav-bar-selected': _vm.selectedSchedule == schedule.ID,
				'show-edit-on-hover': index > 0
			},staticStyle:{"padding-bottom":"30px","position":"relative","display":"inline-block","cursor":"pointer","opacity":"1","height":"34px","float":"left","text-align":"center","padding-left":"28px","padding-right":"28px"},style:(index == 0 ? 'height: 22px;' : ''),on:{"click":function($event){return _vm.goToSchedulePage(schedule)}}},[_c('span',{staticClass:"material-icons",class:{'nav-bar-selected': _vm.selectedSchedule == schedule.ID},staticStyle:{"float":"left","color":"rgba(255,255,255,0.9)","font-size":"16px","font-weight":"400","margin-top":"2px"}},[_vm._v(" date_range ")]),_c('p',{class:{'nav-bar-selected': _vm.selectedSchedule == schedule.ID},staticStyle:{"float":"left","margin-left":"8px","color":"rgba(255,255,255,0.9)","font-size":"14px"},style:({
				'font-weight: 500;': _vm.selectedSchedule == schedule.ID,
				'font-weight: 300;': _vm.selectedSchedule != schedule.ID,
				'show-edit-on-hover': index > 0
			})},[_vm._v(" "+_vm._s(schedule.Role == undefined ? schedule.ID : schedule.Role)+" ")]),(index >= 0)?_c('div',{staticClass:"toggle_opacity",staticStyle:{"position":"absolute","top":"0px","left":"0px","width":"100%","height":"100%"}},[_c('span',{staticClass:"material-icons",staticStyle:{"position":"absolute","right":"0px","color":"rgba(255,255,255,0.9)","font-size":"20px","font-weight":"400"},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.editScheduleTitleSelected(schedule)}]}},[_vm._v(" more_vert ")]),(_vm.showEditScheduleDropdown.ID == schedule.ID)?_c('div',{staticClass:"shadow-2xl more-schedule-title",staticStyle:{"z-index":"9999999","position":"absolute","width":"150px","border-radius":"4px","overflow":"hidden","top":"24px","right":"0px"}},[_c('div',{staticClass:"highlight-hover",staticStyle:{"padding":"10px","width":"100%","font-size":"15px","font-weight":"400","color":"rgba(255,255,255,0.9)"},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.deleteSchedule(schedule)}]}},[_vm._v(" Delete ")])]):_vm._e()]):_vm._e()])}),_c('t-dialog',{staticClass:"default-modal",attrs:{"name":"confirmModal","type":"confirm"},on:{"closed":_vm.onConfirmModalClose}},[_c('template',{slot:"title"},[_vm._v(" Confirm Delete? ")]),_c('div',{staticClass:"modal-content"},[_c('span')]),_c('template',{slot:"icon"},[_c('span')])],2),_c('button',{staticStyle:{"float":"right","color":"rgba(255, 255, 255, 0.9)","background":"rgba(50, 165, 50, 0.85)","font-weight":"400","border-radius":"4px","padding":"2px 10px","font-size":"14px","margin-top":"-2px"},on:{"click":_vm.addNewAssetClicked}},[_vm._v(" Add Asset ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }